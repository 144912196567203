import React from 'react'
import { Link } from 'react-router-dom'
import './HomePage.css'
import { Helmet } from 'react-helmet-async'

export default function HomePage() {
  return (
    <>
    <Helmet>
        <title>Coaching Library</title>
        <meta name="description" content="The #1 database for NFL coaching. View Coaching Staffs and stats of any Team" />
        <link rel='canonical' href='https://coaching-library.com' />
    </Helmet>
    <div className='centerDiv'>
        <div className='homePgCont'>
            <div className='welcome'>
                <h1>Welcome to Coaching Library</h1>
                <p>The #1 database for NFL coaching</p>
            </div>
            <div className='reflexBox'>
                <Link to={'/teams/'} className='linkBox'><h1>Teams</h1><p>View Coaching Staff and Stats of Any Team</p></Link>
                <Link to={'/coaches/'} className='linkBox'><h1>Coaches</h1><p>View History of Any Coach</p></Link>
            </div>
        </div>
    </div>
    </>
  )
}
