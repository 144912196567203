import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'
import logo from '../images/CL-logo.PNG'

export default function Header() {
  return (
    <div className = 'navbar'>
        <div className = "logoBox">
          <Link to={'/'} style={{textDecoration: 'none'}}>
            <img className = 'logo' src = {logo} height='100px' alt = 'Coaching Library'/>
          </Link>
        </div>
        <ul className = 'nav-links'>
            <li className ='nav-item'><Link to='/teams/' >Teams</Link></li>
            <li className ='nav-item'><Link to= '/coaches/'>Coaches</Link></li>
        </ul>
    </div>
  )
}
