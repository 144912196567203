import React, { useState, useEffect }from 'react'
import { Link } from 'react-router-dom'
import './AbrvCoachHistory.css'
import './team-colors-full.css'

export default function AbrvCoachHistory() {

    const [overallCoachHistory, setOverallCoachHistory] = useState([]);
    const [coachHistory, setCoachHistory] = useState([]);
    const [smallScreen, setSmallScreen] = useState(true);

    let coachName = window.location.href.split('/')[4];
    let source = '/api/coach/getAbrvCoachHistory?coach=' + coachName;
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setOverallCoachHistory(data[0]);
                setCoachHistory(
                    data.slice(1, data.length)
                );
            })
        );
    },[source]);

    function handleResize() {

        if(window.innerWidth<1000){
            if (!smallScreen){
                setSmallScreen(true);
            }

        }
        else {
            if (smallScreen){
                setSmallScreen(false);
            }
        }
    }
    handleResize();

    function renderYear(startYear, endYear) {
        if (startYear === endYear){
            return <td className='year'>{startYear}</td>;
        }
        return <td className='year'>{startYear}-{endYear}</td>;
    };

    function renderAchievements(sb, conf, div) {
        let elements = [];
        if (sb > 0){
            elements.push(<span className='superBs' >{sb}x SB</span>);
        }
        if (conf > 0){
            elements.push(<span className='confChamps'>{conf}x CC</span>);
        }
        if (div > 0){
            elements.push(<span className='divChamps'>{div}x DT</span>);
        }
        return elements;
    }

    function renderAbrvHistLarge(){
        return (<table className='abrvHistTable'>
        <thead>
            <tr>
            <th>Year</th>
            <th>Team</th>
            <th>Coaching<br></br> Position</th>
            <th>Season<br></br>Record</th>
            <th>Playoff<br></br> Record</th>
            <th>Achievements</th>
            </tr>
        </thead>
        <tbody>
            <tr className='ovRow'>
                <td className='year'>{overallCoachHistory[1]} Years</td>
                <td>Overall</td>
                <td></td>
                <td>{overallCoachHistory[2]}</td>
                <td>{overallCoachHistory[3]}</td>
                <td className='achBox'>{renderAchievements(overallCoachHistory[4], overallCoachHistory[5], overallCoachHistory[6])}</td>
            </tr>
        {coachHistory.map((row) => (
            <tr className='eraRow'>
                {renderYear(row[3], row[2])}
            <Link id = {row[4] + 'Full'} className= 'teamBox' to = {'/teams/' + row[4] + '/' + row[2] +'/'}>{row[0]}</Link>
            <td>{row[1]}</td>
            <td>{row[5]}</td>
            <td>{row[6]}</td>
            <td className='achBox'>{renderAchievements(row[7], row[8], row[9])}</td>
            </tr>
        ))}
        </tbody>
    </table>)
    }

    function renderAbrvHistSmall(){
        return(
            <div className= 'abrvHistGrid'>
                <div className='ovBox'>
                    <div className='tableText'>Overall</div>
                    <div className='year'>{overallCoachHistory[1]} years in league</div>
                    <div className='recordsBox'>
                    <div  className='recordsColumn'>
                        <h1>Season</h1>
                        <span>{overallCoachHistory[2]}</span>
                    </div>
                    <div className='recordsColumn'>
                        <h1>Playoffs</h1>
                        <span>{overallCoachHistory[3]}</span>
                    </div>
                    </div>
                    <div className='achBox s'>
                    {renderAchievements(overallCoachHistory[4], overallCoachHistory[5], overallCoachHistory[6])}
                    </div>
                
                </div>

                {coachHistory.map((row) => (
                <div className='eraBox'>
                <div className='tableText'>
                    {row[1]}
                </div>
                <Link id = {row[4] + 'Full'} className= 'teamYearBox' to = {'/teams/' + row[4] + '/' + row[2]}>
                    {renderYear(row[3], row[2])}
                    <Link id = {row[4] + 'Full'} className= 'teamBox' to = {'/teams/' + row[4] + '/' + row[2]}>{row[0]}</Link>
                </Link>
                
                <div className='recordsBox'>
                    <div  className='recordsColumn'>
                        <h1>Season</h1>
                        <span>{row[5]}</span>
                    </div>
                    <div className='recordsColumn'>
                        <h1>Playoffs</h1>
                        <span>{row[6]}</span>
                    </div>
                </div>
                <div className='achBox'>
                    {renderAchievements(row[7], row[8], row[9])}
                </div>
                </div>
                ))}

            </div>

        )
    }

    window.addEventListener('resize', handleResize);
  return (
    <div className='abrvHistoryBox'>
        {/* <div className = 'overallHistory'>
            <div className = 'ovHistBox span2'><span className='ovHistLabel'>Years in League</span> <span className='ovHistStat'>{overallCoachHistory[1]} </span></div>
            <div className = 'ovHistBox'><span className='ovHistLabel'>Regular Season<br></br>Record</span> <span className='ovHistStat'>{overallCoachHistory[2]}</span></div>
            <div className = 'ovHistBox'><span className='ovHistLabel'>Playoff <br></br>Record</span> <span className='ovHistStat'>{overallCoachHistory[3]}</span></div>
            <div className = 'ovHistBox'><span className='ovHistLabel'>Conference<br></br> Championships</span> <span className='ovHistStat'>{overallCoachHistory[5]}</span></div>

            <div className = 'ovHistBox'><span className='ovHistLabel'>SuperBowls</span> <span className='ovHistStat'>{overallCoachHistory[4]}</span></div>
        </div> */}
        {smallScreen? renderAbrvHistSmall():renderAbrvHistLarge()}
            
                
      
    </div>
  )
}
