import React, { useState , useEffect } from 'react'
import { useParams} from "react-router-dom"
import ReprCont from '../ReprCont/ReprCont';
import CoachHistory from '../CoachHistory/CoachHistory';
import AbrvCoachHistory from '../AbrvCoachHistory/AbrvCoachHistory';
import { Helmet } from 'react-helmet-async';


export default function CoachPage() {
    let params = useParams()
    const [coachValid , setCoachValid] = useState([false, '', {}]);



    let source = '/api/checkCoachValid?coach_name=' + params.coach_name;
    
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setCoachValid(
                    data
                );
            })
        );
    },[source]);


    if (!coachValid[0]){
        return (
            <div></div>
        )
    }


  return (
    <>
    <Helmet>
        <title>{coachValid[2].title}</title>
        <meta name="description" content={coachValid[2].description} />
        <link rel='canonical' href={coachValid[2].url} />
    </Helmet>

    <div style={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}
    }>
        <div style={{
            textTransform: 'capitalize',
            fontSize: '40px',
            color: 'white',
            fontFamily: 'Merriweather Sans',
            marginTop: '20px',
            whiteSpace: 'nowrap',
        }}>
            {coachValid[1]}
        </div>
        
        <AbrvCoachHistory />
        <ReprCont />
        <CoachHistory />
      
    </div>
    </>
  )
}
