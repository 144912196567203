import React, { useState, useEffect } from 'react'
import './TeamInfoSched.css'
import './team-colors.css'
import { Link } from 'react-router-dom';



export default function TeamInfoSched({showMore}) {
    const [infoSched , setInfoSched] = useState({'info': [], 'sched': []});
    const [schedContent, setSchedContent] = useState([]);

    let link = window.location.href.split('/');
    let source = '/api/teamInfoSched?team=' + link[4] + '&year=' + link[5];
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setInfoSched(
                    data
                );
            })
        );
    },[source]);
  
    useEffect(() =>{

        let content = [];

        if (!showMore){
            setSchedContent(content);
            return;
        }
        content.push(<div className= 'schedTitle'>Regular Season</div>);
        let rows = [];
        let regSeason = infoSched.sched[0];
        let playoffs = infoSched.sched[1];
        for (let i = 0; i<regSeason.length; i++){
            if (regSeason[i][1] === 'Bye'){
                rows.push(
                    <tr className = 'weekRow'>
                        <td className= 'week'> {regSeason[i][0]}</td>
                        <td className= "rowSpacer"></td>
                        <td className= 'result'></td>
                        <td className= "rowSpacer"></td>
                        <td id = {regSeason[i][3]} className= 'opponent'>{regSeason[i][1]}</td>
                </tr>
                )
            }
            else{
                let color = '';
                    if (regSeason[i][2][0] === 'W'){
                        color = 'win';
                    }
                    else if (regSeason[i][2][0] === 'L'){
                        color = 'loss';
                    }
                    else if (regSeason[i][2][0] === 'T'){
                        color = 'tie';
                    }
                rows.push(
                    <tr className = 'weekRow'>
                        <td className= 'week'> {regSeason[i][0]}</td>
                        <td className= "rowSpacer"></td>
                        <td className= {'result '+ color}>{regSeason[i][2]}</td>
                        <td className= "rowSpacer"></td>
                        <Link id = {regSeason[i][3]} className= 'opponent' to = {'/teams/' + regSeason[i][3] + '/' + link[5]}>{regSeason[i][1]}</Link>
                </tr>
                )
            }
        }

        content.push(<table className= 'regSched'>{rows}</table>);

        if (playoffs.length > 0){
            content.push(<div className= 'schedTitle'>Playoffs</div>);

            rows = [];
            for (let i = 0; i<playoffs.length; i++){
                if (playoffs[i][1] === 'Bye'){
                    rows.push(
                        <tr className = 'weekRow'>
                            <td className= 'week'> {playoffs[i][0]}</td>
                            <td className= "rowSpacer"></td>
                            <td className= 'result'></td>
                            <td className= "rowSpacer"></td>
                            <td id = {playoffs[i][3]} className= 'opponent'>{playoffs[i][1]}</td>
                    </tr>
                    )
                }
                else{
                    let color = 'tie';
                    if (playoffs[i][2][0] === 'W'){
                        color = 'win';
                    }
                    else if (playoffs[i][2][0] === 'L'){
                        color = 'loss';
                    }
                    rows.push(
                        <tr className = 'weekRow'>
                            <td className= 'week'> {playoffs[i][0]}</td>
                            <td className= "rowSpacer"></td>
                            <td className= {'result '+ color}>{playoffs[i][2]}</td>
                            <td className= "rowSpacer"></td>
                            <Link id = {playoffs[i][3]} className= 'opponent' to = {'/teams/' + playoffs[i][3] + '/' + link[5]}>{playoffs[i][1]}</Link>

                    </tr>
                    )
                }
    
            }
            content.push(<table className= 'regSched'>{rows}</table>)


        }
    


        setSchedContent(content);
    },[infoSched, showMore]);
  
    return (
    <div className = 'infoSchedBox'>
        <div className= "infoBox">
            <span className= "L">Record</span>
            <span className= "R">{infoSched.info[0]}</span>
            <span className= "L">Division Finish</span>
            <span className= "R">{infoSched.info[1]}</span>
            <span className= "L">Playoff Finish</span>
            <span className= "R">{infoSched.info[2]}</span>
        </div>
        {schedContent}
      
    </div>
  )
}
