import React, { useEffect, useState} from 'react'
import './CoachHistory.css'
import { Link } from 'react-router-dom'


export default function CoachHistory() {

    const [coachHistory, setCoachHistory] = useState([]);

    let coachName = window.location.href.split('/')[4];
    let source = '/api/coach/getCoachHistory?coach=' + coachName;
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setCoachHistory(
                    setDataTiers(data)
                );
            })
        );
    },[source]);



  return (
    <>
    <div className='coachHistHeader'>Full Coaching History</div>
    
    <div className='histTableCont'>
    <table class = 'hist-table'>
        <thead>
            <tr className = 'headerRow'>
            <th>Year</th>
            <th>Team</th>
            <th>Coaching Position</th>
            <th>Record</th>
            <th>Playoff Finish</th>
            <th>Division Finish</th>
            <th><div>
                <span>Offense</span>
                <div className = 'coachHistStatsGridHeader'><span id='PPG'>PPG</span><span id='YPG'>YPG</span><span id= 'PYPG'>PYPG</span><span id='RYPG'>RYPG</span></div>
                </div></th>
            
            <th><div>
                <span>Defense</span>
                <div className = 'coachHistStatsGridHeader'><span id='PPG'>PPG</span><span id='YPG'>YPG</span><span id= 'PYPG'>PYPG</span><span id='RYPG'>RYPG</span></div>
                </div></th>
            </tr>
        </thead>
        <tbody>
        {coachHistory.map((row) => (

            
            <tr class = 'hist-row'>
            <td class = 'year'>{row[0]}</td>
            <td class = 'team' id = {row[3] + 'Full'} ><Link to= {'/teams/' + row[3] + '/' + row[0]+ '/'}className = 'linkText'>{row[1]}</Link></td>
            <td>{row[2]}</td>
            <td>{row[4]}</td>
            <td>{row[5]}</td>
            <td>{row[6]}</td>
            <td><div className = 'coachHistStatsGrid'><span className={'tier' + row[7][2][1]}>{row[7][0]}</span><span className={'tier' + row[8][2][1]}>{row[8][0]}</span><span className={'tier' + row[9][2][1]}>{row[9][0]}</span><span className={'tier' + row[10][2][1]}>{row[10][0]}</span></div></td>
            <td><div className = 'coachHistStatsGrid'><span className={'tier' + row[7][3][1]}>{row[7][1]}</span><span className={'tier' + row[8][3][1]}>{row[8][1]}</span><span className={'tier' + row[9][3][1]}>{row[9][1]}</span><span className={'tier' + row[10][3][1]}>{row[10][1]}</span></div></td>
          </tr>
        ))
        }

            
        </tbody>
    </table>
    </div>
    </>

  )
}

function setDataTiers(data){
    for (let i = 0 ; i < data.length ; i++){
        for (let j =7; j < data[i].length; j++){
            if (data[i][j][2][1] === -1){
                data[i][j][2][1] = 0;
            }
            else{
                data[i][j][2][1] = Math.floor(data[i][j][2][1] /.2) + 1;
            }
            if (data[i][j][3][1] === -1){
                data[i][j][3][1] = 0;
            }
            else{
                data[i][j][3][1] = Math.floor(data[i][j][3][1] /.2) + 1;
            }
        }
    }
    return data;

}
