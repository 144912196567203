import React, { useState, useEffect} from 'react'
import CoachRepr from '../CoachRepr/CoachRepr'
import { useParams} from "react-router-dom"
import TeamInfoCont from '../teamInfoCont/TeamInfoCont'
import TeamTitle from '../TeamTitle/TeamTitle'
import { Helmet } from 'react-helmet-async';


export default function TeamPage() {
    let params = useParams()
    const [metaData , setMetaData] = useState({});
    let source = '/api/team/getMetaData?team=' + params.abrv + '&year=' + params.year;
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setMetaData(
                    data
                );
            })
        );
    },[source]);

  return (
    <>
    <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={metaData.url} />
    </Helmet>
    <div style= {{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

      }}>
      <TeamTitle />
      <TeamInfoCont />
      <div style={{
        fontSize: '30px',
        fontFamily: 'Merriweather Sans',
        margin: '15px',
        marginBottom: '5px',
        color: '#5691b9'}}>
        Coaching Staff</div>
      <CoachRepr team = {params.abrv} year = {params.year} />
    </div>
    </>
  )
}
