import React, { useState , useEffect , useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import bg from '../images/coachReprBG.svg'





export default function CoachRepr(object) {
    const [coachData, setCoachData]= useState([]);
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    let bgImg = new Image();
    bgImg.src = bg;



    const [sizing] = useState({'tier 1': [0.5, 300 , '#283341'], 'tier2o': [0.35, 250, '#70232b'], 'tier2s': [0.35, 250, '#541a61'], 'tier2d': [0.35, 250, '#222280'], 'tier3o': [0.25, 175, '#70232b'], 'tier3d': [0.25, 175, '#222280'], 'tier3s': [0.25, 175, '#541a61'], 'tier4o': [0.2, 150, '#70232b'], 'tier4d': [0.2, 150, '#222280'], 'tier4s': [0.2, 150, '#541a61'], 'tier5': [0.2, 150, '#283341']});
    const [scale] = useState(window.devicePixelRatio);
    const [width, setWidth] =  useState(0);
    const [coachChemo, setCoachChemo] = useState([0,0]);

    let source = '/api/teams/coachData?team=' + object.team + '&year=' + object.year;
    useEffect(() =>{
        fetch(source).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                let coachObjs =  createCoachObjs(data[0]);
                setCoachData(
                    coachObjs
                );
                setCoachChemo(data[1]);
                updateWidth();
            })
        );
    },[source]);

    useEffect(() =>{

        canvasRef.current.height = setCoachPositions(coachData, width, scale, sizing) * scale;

        const c = canvasRef.current


        c.style.width = width + 'px';
        c.width = Math.floor(width * scale);

        

        let ctx = canvasRef.current.getContext('2d');
        ctx.scale(scale,scale);
        resetAndSetupCanvas(c, ctx, bgImg, coachChemo, width);
        drawCoaches(ctx, coachData);

    }, [width, coachData, scale, sizing]);




    const updateWidth = () => {
        let width = window.innerWidth * .95;
        if (width> 1000){
            width = 1000;
        }
        setWidth(width);
    }

    function handleMouseMove(e){
        var rect = canvasRef.current.getBoundingClientRect();
        let c = canvasRef.current;
        let ctx = c.getContext('2d');

        let x = e.pageX - (rect.left + window.scrollX);
        let y = e.pageY - (rect.top + window.scrollY);
        for (let i = 0; i < coachData.length; i++){
            let coach = coachData[i];

            if (x > coach.x - coach.width/2 && x < coach.x + coach.width/2 && y > coach.y - coach.height/2 && y < coach.y + coach.height/2){
                if (!coach.selected){

                    coach.selected = true;
                    resetAndSetupCanvas(c, ctx, bgImg, coachChemo, width);
                    
                    drawLines(ctx, coachData, coach, width);
                    drawCoaches(ctx, coachData);
                    c.style.cursor = 'pointer';
                }
            }
            else{
                if(coach.selected){
                    coach.selected = false;
                    resetAndSetupCanvas(c, ctx, bgImg, coachChemo, width);

                    drawCoaches(ctx, coachData);
                    c.style.cursor = 'default';
                }
            }
        }

    }

    function handleClick(e){
        var rect = canvasRef.current.getBoundingClientRect();

        let x = e.pageX - (rect.left + window.scrollX);
        let y = e.pageY - (rect.top + window.scrollY);

        for (let i = 0; i < coachData.length; i++){
            let coach = coachData[i];
            if (coach.selected){
                if (x > coach.x - coach.xSpace/2 && x < coach.x + coach.xSpace/2 && y > coach.y - coach.ySpace/2 && y < coach.y + coach.ySpace/2){
                    navigate('/coaches/' + coach.name.replaceAll(' ', '_').toLowerCase()+ '/');
                }
            }
        }
    }

    window.addEventListener('resize', updateWidth);
    


  return (
    <canvas ref={canvasRef} style= {{
        backgroundColor: '#1a1c28',
        border:'1px solid #847410',
        borderRadius:'10px'}}
    onMouseMove={(ev)=> handleMouseMove(ev)}
    onClick= {(ev) => handleClick(ev)}>
    
    </canvas>      
  )
}


class Coach{
    constructor(name, position, abrv, tier, index, yearsArray){
        this.name = name;
        this.tier = tier;
        this.position = position;
        if (abrv === 'NA'){
            abrv = '';
        }
        this.abrv = abrv;
        this.index = index;

        this.yearsArray = yearsArray;
        this.x = 0;
        this.y = 0;
        this.topLeft = [0,0];
        this.botRight = [0,0];
        this.xSpace = 0;

       
        this.color = 'white';
        this.ySpace = 0;
        this.width = 0;
        this.height = 0;

        this.selected = false;

    }

    setSize(width, scale, sizing){
        this.color = sizing[this.tier][2];
        this.xSpace = sizing[this.tier][0]* width;
        if (this.xSpace > sizing[this.tier][1]){
            this.xSpace = sizing[this.tier][1];
        }
        this.ySpace = this.xSpace /1.618;
        this.width = this.xSpace*.8;
        this.height = this.ySpace *.8;
        
    }
}

function resetAndSetupCanvas(c, ctx, bgImg, coachChemo, width){
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.drawImage(bgImg, 0, 0, c.width, c.height);
    drawChemoBox(c, ctx, coachChemo, width);

}

function drawChemoBox(c, ctx, coachChemo, width){
    let boxWidth = width * .25;
    let fontsize = width / 40;
    console.log(width);
    let boxHeight = boxWidth/1.618;
    let x = width - boxWidth;
    let y = 0;
    if (width < 800){
        y = 50;
    }
    else{
        y=10;
    }

    // ctx.beginPath();
    // ctx.roundRect(x, y, boxWidth, boxHeight, 0);
    // ctx.fillStyle = '#283341';
    // ctx.fill();
    // ctx.lineWidth = 2;
    // ctx.strokeStyle = '#546783';
    // ctx.stroke()
    ctx.font= fontsize + "px Merriweather Sans";
    ctx.fillStyle = "rgb(34, 139, 34)";
    ctx.textAlign = "center";
    ctx.fillText("Coaching Chemistry", x + boxWidth/2, y+fontsize, boxWidth);
    ctx.fillStyle = '#b7bbc0'
    ctx.font = fontsize*1.2 + "px Merriweather Sans";
    ctx.fillText(coachChemo[0], x + boxWidth/2, y+fontsize*2.25, boxWidth);
    ctx.font = fontsize*.8 + "px Merriweather Sans";
    ctx.fillText(ordinal_suffix_of(coachChemo[1]), x + boxWidth/2 + fontsize*3, y+fontsize*2.25, boxWidth);
    ctx.font = fontsize*.6 + "px Merriweather Sans";

    ctx.fillText("Hover to see years together", x + boxWidth/2, y+fontsize*3.5, boxWidth);
    ctx.font = fontsize*.4 + "px Merriweather Sans";
    ctx.fillText("0                                                   5                                                        20", x + boxWidth/2, y+fontsize*4, boxWidth);
    let lineWidth = width/100 +2;
    let grd = ctx.createLinearGradient(x +10, 0, x+boxWidth-10, 0);
    grd.addColorStop(0,  'rgba(34, 139, 34,  0)');
    grd.addColorStop(1, 'rgba(34, 139, 34,  1)');
    ctx.fillStyle = grd;
    ctx.fillRect(x +10, y+fontsize*4, boxWidth-20, lineWidth);


}

function createCoachObjs(coaches){
    let newCoaches = [];
    for (let i = 0; i < coaches.length; i++){

        let coach = coaches[i];
        let x = new Coach(coach[0], coach[1], coach[2], coach[3], i, coach[4]);
        newCoaches.push(x);
    }
    return newCoaches;
}

function ordinal_suffix_of(i) {
    if (i === null){
        return ''
    }
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return '(' + i + "st)";
    }
    if (j === 2 && k !== 12) {
        return '(' + i + "nd)";
    }
    if (j === 3 && k !== 13) {
        return '(' + i + "rd)";
    }
    return '(' + i + "th)";
}


function setCoachPositions(coaches, width, scale, sizing){
    for (let i=0; i<coaches.length; i++){
        coaches[i].setSize(width, scale, sizing);
    }

    let placedCoaches = [];
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier 1'){
            setPosition(coach, placedCoaches, width, 'N', width/2- coach.xSpace/2, 20, 0);
            placedCoaches.push(coach);
            let fillerCoach = new Coach('filler', 'filler', 'filler', 'tier5', 100, []);
            fillerCoach.botRight = [coach.x - coach.xSpace/2, coach.y + coach.ySpace *.1]
            placedCoaches.push(fillerCoach);
            let fillerCoach2 = new Coach('filler', 'filler', 'filler', 'tier5', 100, []);
            fillerCoach2.botRight = [width, coach.y + coach.ySpace *.1]
            fillerCoach2.topLeft = [coach.x + coach.xSpace/2, coach.y - coach.ySpace /2, width]
            placedCoaches.push(fillerCoach2);
        }

    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier2o'){
            setPosition(coach, placedCoaches, width, 'L', 0,0, width/2);
            placedCoaches.push(coach);
            
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier2d'){
            setPosition(coach, placedCoaches, width, 'R', 0,0, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier2s'){
            setPosition(coach, placedCoaches, width, 'N', 0-coach.xSpace/2 ,0, width/2);
            placedCoaches.push(coach);
        }
    }
    let yLim = 0;
    if (placedCoaches.length > 0){
        yLim = placedCoaches[placedCoaches.length-1].y;
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier3s'){
            setPosition(coach, placedCoaches, width, 'N', 0-coach.xSpace/2 ,0, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier4s'){
            setPosition(coach, placedCoaches, width, 'N', 0-coach.xSpace/2 ,0, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier3o'){
            setPosition(coach, placedCoaches, width, 'L', 0,0, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier3d'){
            setPosition(coach, placedCoaches, width, 'R', 0,0, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier4o'){
            setPosition(coach, placedCoaches, width, 'L', 0,yLim, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier4d'){
            setPosition(coach, placedCoaches, width, 'R', 0,yLim, width/2);
            placedCoaches.push(coach);
        }
    }
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (coach.tier === 'tier5'){
            setPosition(coach, placedCoaches, width, 'O', 0,yLim, width/2);
            placedCoaches.push(coach);
        }
    }
    let height = 0;

    for (let i = 0; i < placedCoaches.length; i++){
        let coach = placedCoaches[i];
        if (coach.y + coach.ySpace/2 > height){
            height = coach.y + coach.ySpace/2;
        }
    }
    return height + 50;
}

function setPosition(coach, placedCoaches, size, dir, startX = 0, startY = 0, permStartX = 0){
    let x = coach.xSpace/2 + startX + permStartX;
    let y = coach.ySpace/2 + startY;
    
    while (true){
        let overlap = false;
        let topLeft = [x - coach.xSpace/2, y - coach.ySpace/2];
        let botRight = [x + coach.xSpace/2, y + coach.ySpace/2];
        for (let i = 0; i < placedCoaches.length; i++){
            let xcoach = placedCoaches[i];
            if (overLap(topLeft, botRight, xcoach.topLeft, xcoach.botRight)){
                overlap = true;
                break;
            }
        }
        if (overlap){
            if (dir === 'N'){
                y += 10;
            }
            else if (dir === 'R'){
                x += 10;
                if (x + coach.xSpace/2 > size){
                    x = coach.xSpace/2 + permStartX;
                    y += 10;
                }
            }
            else if (dir === 'L'){
                x -= 10;
                if (x - coach.xSpace/2 < 0){
                    x = size - permStartX - coach.xSpace/2 ;
                    y += 10;
                }
            }
            else if (dir === 'O'){
                let dif = x - permStartX;
                if (dif > 0){
                    x = permStartX - dif;
                }
                else{
                    x = permStartX - dif + 10;
                }
                if (x + coach.xSpace/2 > size){
                    x = permStartX;
                    y += 10;
                }
            }
        }
        else{
            break;
        }
    }
    coach.x = x;
    coach.y = y;
    coach.topLeft = [x - coach.xSpace/2, y - coach.ySpace/2];
    coach.botRight = [x + coach.xSpace/2, y + coach.ySpace/2];


}

function overLap(l1, r1, l2, r2){
    if (l1[0] > r2[0] || l2[0] > r1[0]){
        return false;
    }

    if (l1[1] > r2[1] || l2[1] > r1[1]){
        return false;
    }

    return true;

}

function createRect(coach, ctx, selectedCoach){
    ctx.beginPath();
    let width = 0;
    let height = 0;
    if (coach.selected){
        width = coach.xSpace;
        height = coach.ySpace;
    }
    else{
        width = coach.width;
        height = coach.height;
    }
    ctx.roundRect(coach.x - width/2, coach.y - height/2, width, height, width/8);
    // ctx.roundRect(coach.x - this.width, coach.x-this.height, coach.x + this.width, coach.y+ this.height, 20);

    ctx.fillStyle = coach.color;
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#546783';
    ctx.stroke()
    let fontsize = width/8;
    ctx.font= fontsize + "px Merriweather Sans";
    ctx.fillStyle = "#b7bbc0";
    ctx.textAlign = "center";
    if (selectedCoach === null){
        ctx.fillText(coach.name, coach.x, coach.y, width);
        ctx.fillText(coach.abrv, coach.x, coach.y + fontsize, width);
    }
    else{
        if (coach.selected){
            ctx.font= "bold " + fontsize*1.2 + " px Merriweather Sans";
            ctx.fillText(coach.name, coach.x, coach.y - height/2 + fontsize, width);
            ctx.font= fontsize + "px Merriweather Sans";
            printAtWordWrap(ctx, coach.position, coach.x, coach.y, fontsize, width)
            ctx.font = fontsize*.6 + "px Merriweather Sans";
            ctx.fillText("Years With Team: " + coach.yearsArray[coach.index], coach.x, coach.y + height/2 - fontsize*.25, width);
    
        }
        else{
            ctx.fillText(coach.name, coach.x, coach.y, width);
            ctx.fillText(coach.abrv, coach.x, coach.y + fontsize, width);
            ctx.font = fontsize*.8 + "px Merriweather Sans";
            let yearsWithCoach = selectedCoach.yearsArray[coach.index];
            ctx.fillText("Years Together: " + yearsWithCoach, coach.x, coach.y + coach.height/2 - fontsize*.25, width);

        }

    }


}
function drawCoaches(ctx, coaches){
    let selectedCoach = null;
    for (let i = 0; i < coaches.length; i++){
        if (coaches[i].selected){
            selectedCoach = coaches[i];
        }

    }

    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        createRect(coach, ctx, selectedCoach);

    }
}

// function fillBoxWithText(ctx, text, x, y, width, height){
     
// }

function printAtWordWrap( context , text, x, y, lineHeight, fitWidth)
{
    fitWidth = fitWidth || 0;
    y = y + lineHeight/2;
    
    
    if (fitWidth <= 0)
    {
        context.fillText( text, x, y );
        return;
    }
    var lines = [];
    var words = text.split(' ');
    var idx = 1;
    while (words.length > 0 && idx <= words.length)
    {
        var str = words.slice(0,idx).join(' ');
        var w = context.measureText(str).width;
        if ( w > fitWidth )
        {
            if (idx===1)
            {
                idx=2;
            }
            lines.push( words.slice(0,idx-1).join(' ') );
            words = words.splice(idx-1);
            idx = 1;
        }
        else
        {idx++;}
    }
    if (lines.length > 2){
        lineHeight = lineHeight * .8;
        context.font= lineHeight + "px Merriweather Sans";
    }
    y = y - (lineHeight * (lines.length))/2;
    for (var i = 0; i<lines.length;i++){
        context.fillText(lines[i], x, y);
        y += lineHeight;
    }
    
    context.fillText( words.join(' '), x, y);
}

function drawLines(ctx, coaches, selectedCoach, width){
    let sortedCoaches =[[0, selectedCoach]];
    for (let i = 0; i < coaches.length; i++){
        let coach = coaches[i];
        if (!coach.selected){
            let distance = Math.sqrt(Math.pow(selectedCoach.x - coach.x, 2) + Math.pow(selectedCoach.y - coach.y, 2));
            for (let j = 0; j < sortedCoaches.length; j++){
                if (distance > sortedCoaches[j][0]){
                    sortedCoaches.splice(j, 0, [distance, coach]);
                    break;
                }
            }
        }
    }

    for (let i = 0; i < sortedCoaches.length; i++){
        let coach = sortedCoaches[i][1];
        if (!coach.selected){
            let yearTog = selectedCoach.yearsArray[coach.index];
            // let opacity = (Math.log(yearTog)/ Math.log(5))/2 +.1;
            let opacity = 1 - Math.E**(-yearTog/5);
            ctx.beginPath();
            ctx.moveTo(coach.x, coach.y);
            ctx.lineTo(selectedCoach.x, selectedCoach.y);
            ctx.lineWidth = width/100 +2;
            ctx.strokeStyle = 'rgba(34, 139, 34, ' + opacity +')';
            ctx.stroke();
        }
    }
}

