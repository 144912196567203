import React, { useState , useEffect } from 'react'
import { Link } from 'react-router-dom';
import './CoachSearch.css';
import { Helmet } from 'react-helmet-async';


export default function CoachSearch() {


    const [query, setQuery] = useState('');
    const [coachList , setCoachList] = useState([]);
    
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        let source = '/api/coachSearch?input='+query;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setCoachList(
                    data
                );
            })
        );
    },[query]);

    function renderCoachList(coaches){
        let boxes = [];
        let even = true;
        if (coaches.length === 0){
            boxes.push( <div className='coachListDiv'>
                <div className='coachCont evenBox'>
                <span className='coachName'>
                    No Results Found For "{query}"
                </span>
                </div>
            </div>)

        }
        for (let i = 0; i < coaches.length; i++){
            boxes.push(
                <div className='coachListDiv'>
                    <Link className = {even ?  'coachCont evenBox': 'coachCont oddBox'} to={`/coaches/${coaches[i][1]}/`}>
                    <span className='coachName'>
                        {coaches[i][0]}
                    </span>
                    <span>{coaches[i][4]}</span>
                    <span>{coaches[i][2]} {coaches[i][3]}</span>
                    </Link>
                </div>
            );
            even = !even;
        }
        

        return (
            <div className='coachListWindow'>
                {boxes}
            </div>
        );
    }


  return (
    <>
        <Helmet>
            <title>Coach Search | NFL Coaching DataBase</title>
            <meta name="description" content= {'Search for a Coach. View their History, stats and colleagues'} />
            <link rel='canonical' href={'https://coaching-library.com/coaches/'} />
        </Helmet>
        <div className='centerDiv'>
            <div style={{
                backgroundColor: '#283241',
                width: 'fit-content',
                padding: '20px 20px',
                borderRadius: '10px',
                border: '1px solid #546783',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <input  className= 'searchBar' placeholder="Search For Coach" onChange={event => setQuery(event.target.value)} />

            {renderCoachList(coachList)}
            </div>


        
        </div>
    </>
  )
}
