import React, { useState , useEffect } from 'react'
import { useParams , Link} from "react-router-dom"
import './TeamYearSelect.css'
import { Helmet } from 'react-helmet-async';



export default function TeamYearSelect() {
    let params = useParams()
    let abrv = params.abrv
    const [myData, setMyData] = useState([]);
    useEffect(() => {

        fetch("/api/teams?team="+abrv).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setMyData(
                    data
                );
                
            })
        );
    }, [abrv]);
    // let content = <div className = 'centerDiv'> <div className = "yearsCont">
    let nums = [];

    let link = window.location.href.split('/');
    
    for (let i = 0; i<2; i++) {
        let even = (i%2 === 0);
        nums.push(<div className= {'num spacer ' + (even ? 'e':'o')}></div>)
    }
    for (let i = 2023; i >= myData[0]; i--) {
        let even = (i%2 === 0);
        nums.push(<Link style={{textDecoration : 'none'}} to={'/teams/' +link[4] + '/' + i +'/'}><div className= {'num ' + (even ? 'e':'o')}>{i}</div></Link>)
    }
    for (let i = 0; i<2; i++) {
        let even = (i%2 === 0);
        nums.push(<div className= {'num spacer ' + (even ? 'e':'o')}></div>)
    }
  return (
    <>
    <Helmet>
        <title>{myData[1] + 'Coaching Staffs | Select Year'}</title>
        <meta name="description" content= {'View Coaching Staffs and Stats of the ' + myData[1]} />
        <link rel='canonical' href={'https://coaching-library.com/teams/' + abrv + '/'} />

    </Helmet>
    <div style={{
        display: 'flex',
        justifyContent: 'center',
    }}>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#283241',
            width: 'fit-content',
            border: '1px solid #546783',
            borderRadius: '10px',
            padding: '10px 20px',
            marginTop: '20px',
        }}>
            
            <div className = 'title-box'>
                <img className='teamLogo' src={require('../logos/'+ link[4] + '-logo.png')} alt=' '></img>
                {myData[1]}
            </div>
            <div className='title'>
                Select Year
            </div>
            <div className='window'>
                <div className='scrollWheel'>
                    {nums}
                </div>
            </div>
        </div>
    </div>
    </>
    )
        
}
