import React, { useState, useEffect }from 'react'
import './TeamTitle.css'
import { useNavigate } from 'react-router-dom';


export default function TeamTitle() {
    const [titleInfo , setTitleInfo] = useState({'name': '', 'startYear': 0});
    const navigate = useNavigate();

    let link = window.location.href.split('/');
    let source = '/api/teamTitle?team=' + link[4] + '&year=' + link[5];
    let year = parseInt(link[5]);
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setTitleInfo(
                    data
                );
            })
        );
    },[source]);



  return (
    <div className='title-container'>
        {year <= titleInfo.startYear ? <div className= 'btn-spacer'></div>:
        <button className = 'year-button' onClick={() => navigate('/teams/' + link[4] + '/' + (year - 1))}>
                {year-1}
        </button>}
        <div className= 'title-Box'>
            <h1 className= 'title-year'>
                {year}
            </h1>
            <h1 className= 'title-text'>
                {titleInfo.name}
            </h1>
        </div>
        {(year >= 2023) ?<div className= 'btn-spacer'></div>:
        <button className = 'year-button' onClick={() =>navigate('/teams/' + link[4] + '/' + (year + 1))}>
            {year + 1}
        </button>}

    </div>
  )
}
