import React, { useState } from 'react'
import './teamInfoCont.css'
import TeamStats from '../TeamStats/TeamStats'
import TeamInfoSched from '../TeamInfoSched/TeamInfoSched';

export default function TeamInfoCont() {
    const [showMore, updateShowMore] = useState(false);

    const changeShowMore = () => {
        updateShowMore(!showMore);
    }

  return (
    <div className = 'centerDiv'>
        <div className='teamInfoCont'>
            <TeamInfoSched showMore={showMore}/>
            <TeamStats showMore={showMore}/>

        
        </div>
        <button className = 'moreStats' onClick={() => changeShowMore()}>
            View {showMore ? "Less" : "More"} Stats
        </button>
    </div>
  )
}

