import React, { useState , useEffect } from 'react'
import CoachReprCoach from '../CoachReprCoach/CoachReprCoach';
import './ReprCont.css'

export default function ReprCont() {
    const [yearList, setYearsList] = useState([[]]);
    const [yearSelectIdx, setYearSelectIdx] = useState(0);

    let link = window.location.href.split('/');

    const nextYear = ()=> {
        if (yearSelectIdx < yearList.length - 1){
            setYearSelectIdx(yearSelectIdx + 1)
        }
    }

    const prevYear = ()=> {
        if (yearSelectIdx > 0){
            setYearSelectIdx(yearSelectIdx - 1)
        }
    }



    let source = '/api/coach/getYearsList?coach_name='+  link[4]
    useEffect(() =>{
        fetch(source).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setYearsList(
                    data
                );
                setYearSelectIdx(
                    data.length -1
                );
            })
        );
    },[source]);






  return (
    <div>
        <div className='staffTitle'>
        <button className= {yearSelectIdx === 0?'cycleButton prev': 'cycleButton prev active'}  onClick={prevYear}><div className='triangle'></div></button>
        <div className='teamTitle'>{yearList[yearSelectIdx][0]}<br></br>{yearList[yearSelectIdx][1]}</div>
        <button className= {yearSelectIdx === yearList.length-1?'cycleButton next': 'cycleButton next active'} onClick={nextYear}><div className='triangle'></div></button>
        </div>
        <CoachReprCoach abrv={yearList[yearSelectIdx][2]} year={yearList[yearSelectIdx][0]}/>

      
    </div>
  )
}


