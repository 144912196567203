import React from 'react'
import './TeamsHome.css'
import atl from '../logos/atl-logo.png'
import nwe from '../logos/nwe-logo.png'
import nor from '../logos/nor-logo.png'
import tam from '../logos/tam-logo.png'
import car from '../logos/car-logo.png'
import lar from '../logos/lar-logo.png'
import sfo from '../logos/sfo-logo.png'
import sea from '../logos/sea-logo.png'
import ari from '../logos/ari-logo.png'
import gnb from '../logos/gnb-logo.png'
import min from '../logos/min-logo.png'
import chi from '../logos/chi-logo.png'
import det from '../logos/det-logo.png'
import nyg from '../logos/nyg-logo.png'
import was from '../logos/was-logo.png'
import dal from '../logos/dal-logo.png'
import phi from '../logos/phi-logo.png'
import mia from '../logos/mia-logo.png'
import buf from '../logos/buf-logo.png'
import nyj from '../logos/nyj-logo.png'
import den from '../logos/den-logo.png'
import kan from '../logos/kan-logo.png'
import lac from '../logos/lac-logo.png'
import lvr from '../logos/lvr-logo.png'
import pit from '../logos/pit-logo.png'
import cle from '../logos/cle-logo.png'
import cin from '../logos/cin-logo.png'
import bal from '../logos/bal-logo.png'
import jax from '../logos/jax-logo.png'
import ind from '../logos/ind-logo.png'
import htx from '../logos/htx-logo.png'
import ten from '../logos/ten-logo.png'
import {Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function TeamsHome() {
  return (
    <>
    <Helmet>
        <title>Coaching Library - Teams | NFL Coaching DataBase</title>
        <meta name="description" content="The #1 database for NFL coaching. View Coaching Staffs and stats of any Team" />
        <link rel='canonical' href='https://coaching-library.com/teams/' />
    </Helmet>
    <div className = 'centerDiv'>
        <div className = 'thTitle'>
            Select A Team
        </div>
        <div className = "bigContainer">
            <div className = 'confContainer'>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                        NFC North
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/gnb/"><img src={gnb} alt= 'Green Bay Packers'/></Link>
                        <Link to = "/teams/min/"><img src={min} alt= 'Minnesota Vikings'/></Link>
                        <Link to = "/teams/chi/"><img src={chi} alt= 'Chicago Bears'/></Link>
                        <Link to = "/teams/det/"><img src={det} alt= 'Detroit Lions'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                            NFC East
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/phi/"><img src={phi} alt= 'Philadelphia Eagles'/></Link>
                        <Link to = "/teams/dal/"><img src={dal} alt= 'Dallas Cowboys'/></Link>
                        <Link to = "/teams/was/"><img src={was} alt= 'Washington Football Team'/></Link>
                        <Link to = "/teams/nyg/"><img src={nyg} alt= 'New York Giants'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                            NFC South
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/atl/"><img src={atl} alt= 'Atlanta Falcons'/></Link>
                        <Link to = "/teams/nor/"><img src={nor} alt= 'New Orleans Saints'/></Link>
                        <Link to = "/teams/car/"><img src={car} alt= 'Carolina Panthers'/></Link>
                        <Link to = "/teams/tam/"><img src={tam} alt= 'Tampa Bay Buccaneers'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                        NFC West
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/sfo/"><img src={sfo} alt= 'San Francisco 49ers'/></Link>
                        <Link to = "/teams/sea/"><img src={sea} alt= 'Seattle Seahawks'/></Link>
                        <Link to = "/teams/lar/"><img src={lar} alt= 'Los Angeles Rams'/></Link>
                        <Link to = "/teams/ari/"><img src={ari} alt= 'Arizona Cardinals'/></Link>
                    </div>
                </div>

            </div>
            <div className = 'confContainer'>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                        AFC North
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/pit/"><img src={pit} alt= 'Pittsburgh Steelers'/></Link>
                        <Link to = "/teams/bal/"><img src={bal} alt= 'Baltimore Ravens'/></Link>
                        <Link to = "/teams/cle/"><img src={cle} alt= 'Cleveland Browns'/></Link>
                        <Link to = "/teams/cin/"><img src={cin} alt= 'Cincinnati Bengals'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                        AFC East
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/buf/"><img src={buf} alt= 'Buffalo Bills'/></Link>
                        <Link to = "/teams/mia/"><img src={mia} alt= 'Miami Dolphins'/></Link>
                        <Link to = "/teams/nyj/"><img src={nyj} alt= 'New York Jets'/></Link>
                        <Link to = "/teams/nwe/"><img src={nwe} alt= 'New England Patriots'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                        AFC South
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/jax/"><img src={jax} alt= 'Jacksonville Jaguars'/></Link>
                        <Link to = "/teams/htx/"><img src={htx} alt= 'Houston Texans'/></Link>
                        <Link to = "/teams/ten/"><img src={ten} alt= 'Tennessee Titans'/></Link>
                        <Link to = "/teams/ind/"><img src={ind} alt= 'Indianapolis Colts'/></Link>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = 'divisionName'>
                            AFC West
                    </div>
                    <div className = 'divisionContainer'>
                        <Link to = "/teams/kan/"><img src={kan} alt= 'Kansas City Chiefs'/></Link>
                        <Link to = "/teams/lvr/"><img src={lvr} alt= 'Las Vegas Raiders'/></Link>
                        <Link to = "/teams/den/"><img src={den} alt= 'Denver Broncos'/></Link>
                        <Link to = "/teams/lac/"><img src={lac} alt= 'Los Angeles Chargers'/></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
