import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
        <div className='copyRight'>
            <p>&copy;2023 Coaching Library. All Rights Reserved</p>
            <p>Created by Dane Laufer and Will Boyd</p>
        </div>



      
    </div>
  )
}
