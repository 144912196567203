import React, { useEffect } from 'react'
import Header from './header/header.js'
import { Routes, Route } from 'react-router-dom';
import TeamsHome from './TeamsHome/TeamsHome.js';
import TeamYearSelect from './TeamYearSelect/TeamYearSelect.js';
import TeamPage from './TeamPage/TeamPage.js';
import CoachPage from './CoachPage/CoachPage.js';
import Footer from './Footer/Footer.js';
import CoachSearch from './CoachSearch/CoachSearch.js';
import HomePage from './HomePage/HomePage.js';
import ReactGA from 'react-ga';

export default function App() {
  ReactGA.initialize("G-5W804B6H7W");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Header />
      <div style= {{
        minHeight: 'calc(100vh - 200px)'
      }}>
        <Routes>
          <Route path = '/' element = {<HomePage />} />
          <Route path = '/teams' element = {<TeamsHome />} />
          <Route path = '/teams/:abrv' element = {<TeamYearSelect />} />
          <Route path = '/teams/:abrv/:year' element=  {<TeamPage />} />
          <Route path = '/coaches' element=  {<CoachSearch />} />
          <Route path = '/coaches/:coach_name' element=  {<CoachPage />} />


        </Routes>
      </div>
      <Footer />
      
    </>

  )
}
