import React, { useState , useEffect } from 'react'
import './TeamStats.css'


export default function TeamStats({showMore}) {
    const [stats , setStats] = useState({});
    const [rowsContent, setRowsContent] = useState([])
    const order = [['Points_Per_Game', true], ['Yards_Per_Game', true], ['Pass_Yards_Per_Game', true], ['Rush_Yards_Per_Game', true], ['Yards_Per_Play', false], ['Pass_Yards_Per_Attempt', false], ['Rush_Yards_Per_Attempt', false], ['TD_Per_Game', false], ['FG_Per_Game', false], ['Third_Down_%', false], ['Fourth_Down_%', false], ['Red_Zone_Td_%', false], ['Turnovers_Forced', true], ['Interceptions_Forced', false], ['Fumbles_Recovered', false], ['TOs_On_Downs_Forced', false], ['Safeties_Forced', false], ['Sacks', false], ['Penalties_Per_Game', true], ['Penalty_Yards_Per_Game', false]]

    let link = window.location.href.split('/');
    let abrv = link[4];
    let source = '/api/teamStats?team=' + link[4] + '&year=' + link[5];
    useEffect(() =>{
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(source, {signal : signal}).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                setStats(
                    data 
                );
            })
        );
    },[source]);



    useEffect(() =>{
        let content = []
        let even = true;
        for (let i = 0 ; i < order.length ; i++){
            let key = order[i][0];
            


            
            if (key in stats){
                if (stats[key][0] === 'N/A'){
                    continue;
                }
                if (showMore || order[i][1]){
                    let forTier = ''
                    let oppTier = ''
                    if (stats[key][2][1] === -1){
                        forTier = 'data tier0'
                        oppTier = 'data tier0'
                    }
                    else{
                        forTier = 'data tier' + (Math.floor(stats[key][2][1] /.2) + 1)
                        oppTier = 'data tier' + (Math.floor(stats[key][3][1] /.2) + 1)
                    }


                    content.push(
                        <tr className = {even ? 'even': 'odd'}>
                        <td className= "label">{key.replaceAll('_', ' ')}</td>
                        <td className= {forTier}><span className= 'stat'>{stats[key][0]}</span><span className= 'rank'>{ordinal_suffix_of(stats[key][2][0])}</span></td>
                        <td className= "rowSpacer"></td>
                        <td className= {oppTier}><span className= 'stat'>{stats[key][1]}</span><span className= 'rank'>{ordinal_suffix_of(stats[key][3][0])}</span></td>
                        </tr>
                    )
                    even = !even;
                }
            }
        }

        setRowsContent(content);
    },[stats, showMore]);
        



  return (
    <>
    <table className = "statsTable">
        <thead>
            <tr className = "header">
                <th className = 'statsTitle fadeBG'>Team Stats</th>
                <th className='baseBG'>{abrv.toUpperCase()}</th>
                <th className = "rowSpacer baseBG"></th>
                <th className='baseBG'>Opp</th>
            </tr>
        </thead>
        <tbody>
            {rowsContent}
        </tbody>
    </table>
    </>
  )
}

function ordinal_suffix_of(i) {
    if (i === null){
        return ''
    }
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return '(' + i + "st)";
    }
    if (j === 2 && k !== 12) {
        return '(' + i + "nd)";
    }
    if (j === 3 && k !== 13) {
        return '(' + i + "rd)";
    }
    return '(' + i + "th)";
}
